define(['app', 'siteObj', '$window', '$console'], function(app, siteObj, $window, $console) {

  var divAutoComplete;

  var instantSearch = {

    config: {
      _searchField: document.getElementById('search-text'),
      _instantSearchEndpoint: '/' + siteObj.customerLocale + '/' + siteObj.currencyType
        + '/instantsearch.nav?autocompleteRequest=',
      _lastKeyPress: null,
      _specialKeys: {
        'UP': 38,
        'DOWN': 40,
        'RETURN': 13,
        'ESC': 27
      },
      _results: [],
      _lastSearchString: null,
      _lastSearchTimeout: null,
      _minChars: 3,
      _visible: false,
      _activeClass: 'ac-active',
      _queryExec: false,
      _hiddenClassName: 'hidden',
      _selectedIndex: null,
      _previousSelected: null,
      _resultsClassName: '.ac-option'
    },

    _init: function(config) {

      if (config) { //overwrite default config
        instantSearch.config = config;
      }
      if (instantSearch.config._searchField) {
        // create a div to attach to body later (in common.js)
        divAutoComplete = document.createElement('div');
        divAutoComplete.setAttribute('id', 'autocomplete');
        divAutoComplete.className = 'autocomplete';
        //switch off browser based autocomplete
        instantSearch.config._searchField.setAttribute('autocomplete', 'off');
        //safari tries to autocorrect spelling - pointless as we do this in ES anyway
        instantSearch.config._searchField.setAttribute('autocorrect', 'off');
        instantSearch._bindEvents();
        instantSearch._attachInstantSearch(divAutoComplete);

        var minSearchChars = instantSearch.config._searchField.getAttribute('data-min-chars');
        instantSearch.config._minChars = parseInt(minSearchChars) || instantSearch.config._minChars;
      } else {
        if (config) {
          $console.info('Cannot initialize instantSearch, no element found with value of config._searchField');
        } else {
          $console.info('Cannot initialize instantSearch, no element found with ID #search-text - if this is a new header then please ignore this info message');
        }

      }
    },


    _attachInstantSearch: function(divAutoComplete) {
      // attach the autocomplete element to the DOM so it's ready to be populated

      var targetElement = null;

      if (siteObj.siteIsMobile) {
        targetElement = document.getElementById('header');
      } else {
        var searchBox = document.querySelector('#searchForm');
        targetElement = searchBox.parentNode;
      }

      targetElement.appendChild(divAutoComplete);
    },

    _show: function() {
      if (!instantSearch.config._visible && instantSearch.config._searchField !== null) {

        app.element.removeClass(instantSearch.config._hiddenClassName, divAutoComplete);
        instantSearch.config._visible = true;
      }
    },

    _hide: function() {
      if (instantSearch.config._visible) {
        app.element.addClass(instantSearch.config._hiddenClassName, divAutoComplete);
        if (instantSearch.config._previousSelected !== null) {
          app.element.removeClass(instantSearch.config._activeClass, instantSearch.config._previousSelected.children[0]);
        }
        instantSearch.config._selectedIndex = null;
        instantSearch.config._visible = false;
      }
    },

    _bindEvents: function() {
      app.element.on('keydown', instantSearch._keyDownEventHandler, instantSearch.config._searchField);
      app.element.on('keyup', instantSearch._keyUpEventHandler, instantSearch.config._searchField);
      app.element.on('focus', instantSearch._focusEventHandler, instantSearch.config._searchField);
      app.element.on('blur', instantSearch._blurEventHandler, instantSearch.config._searchField);
    },

    _keyDownEventHandler: function(e) {
      instantSearch.config._lastKeyPress = e.keyCode;

      if (e.keyCode === instantSearch.config._specialKeys.RETURN) {
        // only change the behaviour if there is a selected item in the autocomplete list
        // otherwise it will interfere with the default form submit
        if (instantSearch.config._selectedIndex !== null) {
          (e.preventDefault) ? e.preventDefault() : e.returnValue = false;
          instantSearch.config._results[instantSearch.config._selectedIndex].children[0].click();
        }
      }
    },

    _keyUpEventHandler: function(e) {
      instantSearch.config._lastKeyPress = e.keyCode;

      switch (e.keyCode) {
        case instantSearch.config._specialKeys.UP:
          instantSearch._keyNavigationHandler('up');
          break;
        case instantSearch.config._specialKeys.DOWN:
          instantSearch._keyNavigationHandler('down');
          break;
        case instantSearch.config._specialKeys.ESC:
          instantSearch._hide();
          break;
        default:
          // only fire off ajax when valid keys are pressed - any letter or number or del or backspace
          if ((e.keyCode <= 90 && e.keyCode >= 48) || e.keyCode === 8 || e.keyCode === 46) {
            // no special keys were pressed, do as normal
            // set what the current search is as we need it for getting round the issue of any
            // default text in the search box before someone types in a valid search term
            instantSearch.config._lastSearchString = e.target.value;

            if (e.target.value.length >= instantSearch.config._minChars) {
              clearTimeout(instantSearch._lastSearchTimeout);
              // limit search requests to one per half second
              instantSearch._lastSearchTimeout = $window.setTimeout(instantSearch._doSearch, 500);
            } else {
              instantSearch._hide();
            }
          }
          break;
      }
    },

    _doSearch: function() {
      instantSearch.config._queryExec = true;
      return app.ajax.get({
        url: instantSearch.config._instantSearchEndpoint + instantSearch.config._lastSearchString,
        dataType: 'JSON',
        error: function(response) {
          instantSearch._errorCallback(response);
        },
        success: function(response) {
          instantSearch._successCallback(response);
        }
      });
    },

    _keyNavigationHandler: function(direction) {
      if (instantSearch.config._results.length) {

        if (direction === 'up') {
          if (instantSearch.config._selectedIndex === null || instantSearch.config._selectedIndex === 0) {
            instantSearch.config._selectedIndex = null;
          } else {
            instantSearch.config._selectedIndex--;
          }
        } else if (direction === 'down') {
          if (instantSearch.config._selectedIndex === null) {
            instantSearch.config._selectedIndex = 0;
          } else if (instantSearch.config._selectedIndex >= instantSearch.config._results.length - 1) {
            instantSearch.config._selectedIndex = instantSearch.config._results.length - 1;
          } else {
            instantSearch.config._selectedIndex++;
          }
        }

        if (instantSearch.config._previousSelected !== null) {
          app.element.removeClass(instantSearch.config._activeClass, instantSearch.config._previousSelected.children[0]);
        }

        if (instantSearch.config._selectedIndex !== null) {
          app.element.addClass(instantSearch.config._activeClass, instantSearch.config._results[instantSearch.config._selectedIndex].children[0]);
          instantSearch.config._previousSelected = instantSearch.config._results[instantSearch.config._selectedIndex];
        }
      }
    },

    _focusEventHandler: function(e) {
      if (instantSearch.config._queryExec && e.target.value.length >= instantSearch.config._minChars && e.target.value === instantSearch.config._lastSearchString) {
        instantSearch._show();
      }
    },

    _blurEventHandler: function() {
      $window.setTimeout(function() {
        instantSearch._hide();
      }, 250);
    },

    _successCallback: function(response) {
      divAutoComplete.innerHTML = response;
      instantSearch._show();
      instantSearch.config._results = divAutoComplete.querySelectorAll(instantSearch.config._resultsClassName);
    },

    _errorCallback: function(response) {
      $console.log('error:' + response);
    }

  };

  instantSearch._init();
  return instantSearch;
});
